import React, { useState, useEffect, useCallback } from 'react';

export const useHttpGetJSON = (initialUrl = null, initialCallback = null) => {
    console.log('useHttpGetJSON.js invoked');
    console.log('useHttpGetJSON.js initialUrl: '+initialUrl);

    const [url, setUrl] = useState(initialUrl);
    const [callback, setCallback] = useState(initialCallback);
    const [status, setStatus] = useState("idle");
    const [value, setValue] = useState(null);
    const [error, setError] = useState(null);

    const execute = useCallback(() => {
        console.log('useHttpGetJSON.js useCallback() execute() invoked');
        console.log('useHttpGetJSON.js useCallback() url: '+url);

        if(!url){
            setStatus("error")
            setError("URL not set");
        }

        console.log('useHttpGetJSON.js useCallback() setStatus pending');
        setStatus("pending");
        setValue(null);
        setError(null);

        return fetch(url)
        .then((response) => {
            console.log('useHttpGetJSON.js useCallback() response returned');
            console.log('useHttpGetJSON.js useCallback() response.status: '+response.status);
            console.log('useHttpGetJSON.js useCallback() response.type: '+response.type);
            return response.json()
        })
		.then(jsonObj => {
            console.log('useHttpGetJSON.js useCallback() jsonObj returned');

            if (typeof callback === "function") {
                console.log('useHttpGetJSON.js useCallback() use callback function');
                //let [returnStatus, returnValue] = callback(jsonObj);
                //setValue(returnValue);
                //setStatus(returnStatus);
            }
            else{
                console.log('useHttpGetJSON.js useCallback() no callback function');
                console.log('useHttpGetJSON.js useCallback() jsonObj: '+JSON.stringify(jsonObj));
                setValue(jsonObj);
                setStatus("success");
            }
        })
        .catch((error) => {
            console.log('useHttpGetJSON.js useCallback() fetch error: '+error);
            setError(error);
            setStatus("error");
        });
    }, [url, callback]);

    useEffect(() => {
        console.log('useHttpGetJSON useEffect() [initialUrl] invoked');
        execute();
    },[initialUrl]);

    return [ value, execute, status, error, setUrl, setCallback ];
};    
