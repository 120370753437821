import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import AppForm from './AppForm';
import Hint from './components/hints/Hint';

function Main(){
    let navigate = useNavigate();

  	const submitForm = (formState) => {
		console.log('Main.js: this.submitForm() invoked');
        console.log('Main.js: formState = ' + JSON.stringify(formState));
        if(isNaN(formState.enterYear)){
            alert('Year is not a number');
            return false;
        }

		if(formState.enterYear < 1913 || formState.year > 2021){
            alert('Enter a year between 1913 and 2021');
            return false;
        }

		navigate('/results/'+formState.enterYear+'/'+formState.enterAmount);
  	}

    return (
        <>
            <AppForm submit={submitForm} />
            <div className='my-3'><Hint /></div>
        </>
    );
}

export default Main;