import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import ErrorBoundary from './ErrorBoundary';
import FunFact from './components/funfacts/FunFact';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './style.css';

function Results(props){
	console.log('Results.js invoked');

	let { year, amount } = useParams();

	console.log('Results.js props.cpiData: '+JSON.stringify(props.cpiData));
	if(!props.cpiData){ return null; }

	const todayAmount = () => {
		console.log('cpiData: '+JSON.stringify(props.cpiData));
		console.log('cpi value: '+JSON.stringify(props.cpiData[year]));

		let todayAmnt = parseFloat(amount);
		todayAmnt = (props.cpiData['CURRENT'] / props.cpiData[year]) * amount;
		console.log('todayAmnt: ' + JSON.stringify(todayAmnt));
		return todayAmnt.toFixed(2);
	}

	return (
		<ErrorBoundary>
			<div id='results' className='p-3'>
				<p className='text-center'> ${amount} in {year} would be worth ${todayAmount()} today</p>

				<div className="d-flex justify-content-center">
					<button className="btn btn-light btn-lg px-5" onClick={props.resetForm}>Try again?</button>
				</div>
			</div>

			<div className="py-3"><FunFact cpiData={props.cpiData} year={year} /></div>
		</ErrorBoundary>
	);
}

export default Results;
