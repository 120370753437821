import './themes.css';

function hexToRgb(hex){
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

    return result ? parseInt(result[1], 16)+","+parseInt(result[2], 16)+","+parseInt(result[3], 16) : null;
}

function Theme(props){
    const themes = require('./themes.json');

    let theme_details = themes.find(choice => choice.theme == props.theme);
    let body = theme_details.body;
    let body_text = theme_details.body_text;
    
    let primary = theme_details.primary;
    let secondary = theme_details.secondary;
    let success = theme_details.success;
    let info = theme_details.info;
    let warning = theme_details.warning;
    let danger = theme_details.danger;
    let light = theme_details.light;
    let dark = theme_details.dark;

    let primary_text = theme_details.primary_text;
    let secondary_text = theme_details.secondary_text;
    let success_text = theme_details.success_text;
    let info_text = theme_details.info_text;
    let warning_text = theme_details.warning_text;
    let danger_text = theme_details.danger_text;
    let light_text = theme_details.light_text;
    let dark_text = theme_details.dark_text;

    let primary_border = theme_details.primary_border;
    let secondary_border = theme_details.secondary_border;
    let success_border = theme_details.success_border;
    let info_border = theme_details.info_border;
    let warning_border = theme_details.warning_border;
    let danger_border = theme_details.danger_border;
    let light_border = theme_details.light_border;
    let dark_border = theme_details.dark_border;

    let primary_hover = theme_details.primary_hover;
    let secondary_hover = theme_details.secondary_hover;
    let success_hover = theme_details.success_hover;
    let info_hover = theme_details.info_hover;
    let warning_hover = theme_details.warning_hover;
    let danger_hover = theme_details.danger_hover;
    let light_hover = theme_details.light_hover;
    let dark_hover = theme_details.dark_hover;    

    let primary_rgb = hexToRgb(theme_details.primary);
    let secondary_rgb = hexToRgb(theme_details.secondary);
    let success_rgb = hexToRgb(theme_details.success);
    let info_rgb = hexToRgb(theme_details.info);
    let warning_rgb = hexToRgb(theme_details.warning);
    let danger_rgb = hexToRgb(theme_details.danger);
    let light_rgb = hexToRgb(theme_details.light);
    let dark_rgb = hexToRgb(theme_details.dark);

    return (
        <style type='text/css'>
            :root {`{`}
                --bs-body:{body};
                --bs-body-text:{body_text};
                --bs-primary:{primary};
                --bs-secondary:{secondary};
                --bs-success:{success};
                --bs-info:{info};
                --bs-warning:{warning};
                --bs-danger:{danger};
                --bs-light:{light};
                --bs-dark:{dark};
                --bs-primary-border:{primary_border};
                --bs-secondary-border:{secondary_border};
                --bs-success-border:{success_border};
                --bs-info-border:{info_border};
                --bs-warning-border:{warning_border};
                --bs-danger-border:{danger_border};
                --bs-light-border:{light_border};
                --bs-dark-border:{dark_border};
                --bs-primary-text:{primary_text};
                --bs-secondary-text:{secondary_text};
                --bs-success-text:{success_text};
                --bs-info-text:{info_text};
                --bs-warning-text:{warning_text};
                --bs-danger-text:{danger_text};
                --bs-light-text:{light_text};
                --bs-dark-text:{dark_text};
                --bs-primary-hover:{primary_hover};
                --bs-secondary-hover:{secondary_hover};
                --bs-success-hover:{success_hover};
                --bs-info-hover:{info_hover};
                --bs-warning-hover:{warning_hover};
                --bs-danger-hover:{danger_hover};
                --bs-light-hover:{light_hover};
                --bs-dark-hover:{dark_hover};
                --bs-primary-rgb:{primary_rgb};
                --bs-secondary-rgb:{secondary_rgb};
                --bs-success-rgb:{success_rgb};
                --bs-info-rgb:{info_rgb};
                --bs-warning-rgb:{warning_rgb};
                --bs-danger-rgb:{danger_rgb};
                --bs-light-rgb:{light_rgb};
                --bs-dark-rgb:{dark_rgb};
                {`}`}
        </style>
        );
}

export default Theme;
