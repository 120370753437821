import React, { useState } from 'react';
import {
  Routes,
  Route,
  Link,
  useNavigate
} from "react-router-dom";
import { useLocalStorage } from './components/hooks/useLocalStorage';
import { useHttpGetJSON } from './components/hooks/useHttpGetJSON';
import ThemeSelector from './components/themes/ThemeSelector';
import Theme from './components/themes/Theme';
import Main from './Main';
import Results from './Results';
import About from './About';

import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.css';

import './style.css';

import logo from './images/logo.png';
import AvocadoToast from './images/AvocadoToast.svg';

function App (){
	const [cpiData] = useHttpGetJSON('/data/cpiData.json', true);
	console.log('App.js cpiData: '+JSON.stringify(cpiData));

	const [year, setYear] = useState('');
	const [amount, setAmount] = useState('');
        
    const [theme, setTheme] = useLocalStorage("theme","blue");
    const [showThemeSelector, setShowThemeSelector] = useState(false);

	let navigate = useNavigate();

	const resetForm = () => {
		setYear('');
		setAmount('');
		navigate('/');
	}

	return (
		<>
		<Theme theme={theme} />
		<div className="container d-flex justify-content-center">
          	<div className="content">
                <div className ="headerdiv">
                  	<button className='btn border-0 shadow-none' onClick={resetForm}><img className ="img-fluid mt-1 mt-md-5 logo" src={logo}  /></button>
                </div>

			<Routes>
				<Route path="/about" element={<About />} />
				<Route path="/results/:year/:amount" element={<Results cpiData={cpiData} resetForm={resetForm} />} />
				<Route path="/" element={<Main cpiData={cpiData} />} />
			</Routes>

				<div id='bottom_spacer' />
			</div>
      	</div>
		<div id='avocado'><button className='btn border-0 shadow-none' onClick={() => navigate('/about')}><img src={AvocadoToast} alt='' /></button></div>
		<div id='themebtn'><button className='btn btn-primary border-0 shadow-none' onClick={() => setShowThemeSelector(true)}><i class="fa-solid fa-palette" /></button></div>
		<ThemeSelector show={showThemeSelector} setShow={setShowThemeSelector} setTheme={setTheme} />
		</>
    	);
}

export default App;
