import React, { useState } from 'react';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './style.css';


function AppForm(props){
	const [state, setState] = useState({});

  	const handleChange = (event) => {
    		console.log('handleChange: event.target.name = '+event.target.name);
    		console.log('handleChange: event.target.value = '+event.target.value);

                setState({...state,...{[event.target.name]: event.target.value}});
  	};

  	const submitForm = (event) => {
    		event.preventDefault();
    		props.submit(state);
  	};

    return (
<>
<form onSubmit={submitForm}>
	<div className="form-floating mb-3">
        	<input id="enterYear" name="enterYear" className="form-control" type='text' placeholder="Enter Year" required onChange={handleChange}/>
                <label htmlFor="enterYear" className='text-dark'> Enter Year</label>
       	</div>

        <div className="form-floating mb-3">
                <input id="enterAmount" name="enterAmount" className="form-control text-dark" type='text' placeholder="Enter Amount" required onChange={handleChange}/>
                <label htmlFor="enterAmount" className='text-dark'> Enter Dollar Amount</label>
        </div>

        <div className="d-flex justify-content-center">
		<button className="btn btn-light btn-lg px-5 d-flex align-self-center"><i className="align-self-center fa-solid fa-2xl fa-calculator"></i><span className='m-3 fs-1 fw-bold'>CalCuLATe</span></button>
        </div>
</form>
</>
    );
}

export default AppForm;
